
// Code dealing with search/collection page facets

// eslint-disable-next-line import/no-named-as-default
import $ from '../util/jquery.js';


$(() => {
  // see if page is using JS to do XHR to get facets _after_ (not during) main page load
  $('.js-facets-xhr').each(() => {
    // indeed - use the same URL w/ some flags
    let url = location.href.concat('&headless=1&facets_xhr=facets');

    // FUTURE: Might want to load FTS facets async
    const isFullTextSearch = $(this).attr('data-search-type') === 'text';
    if (isFullTextSearch) url += '&sin=TXT';

    $.get(url, (htm) => {
      // inject the markup back in by replacing the "loading.." placeholders
      const facets_markup = $(htm).find('.columns-facets').get(0).innerHTML;
      $('.columns-facets').html(facets_markup);
    });
  });
});
